import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn, UseFormSetValue } from 'react-hook-form'
import { Grid, MaskedTextField, Select, TextField, Multiselect } from '@stationkim/front-ui'
import { EBenefitType, ECardUsage, EFormMode } from '@/main/enums'
import { BenefitBindDialogContext, defaultValues, EBindCardForm, TBenefitBind } from '../benefitBindDialog'
import { TOperatorCardOption, TOperatorOption } from '../../../../hooks/useAvailableBenefits'

interface CardFormProps {
  id: string
  visible?: boolean
  existentBenefitsBinds?: TBenefitBind[]
}

const requiredFieldRule = { required: 'Campo requerido' }
type TSelectOptions = {
  operators: TOperatorOption[]
  cards: TOperatorCardOption[]
}

type TResetOption = 'benefitType' | 'operator' | 'operatorCard'

const resetSelections = (setValue: UseFormSetValue<EBindCardForm>, ignore: TResetOption[] = []) => {
  ;['benefitType', 'operator', 'operatorCard'].forEach((field) => {
    if (ignore.includes(field as TResetOption)) return
    setValue(field as keyof EBindCardForm, defaultValues[field])
  })
}

export const CardForm = ({
  id,
  visible,

  existentBenefitsBinds,
}: CardFormProps) => {
  const { form, mode, setMode, onSubmit, benefits, card, isLoadingBenefitData, isLoadingCardData, setCardName } =
    useContext(BenefitBindDialogContext)
  const [options, setOptions] = useState<TSelectOptions>({ operators: [], cards: [] })

  const [benefitType, operator, operatorCard] = form.watch(['benefitType', 'operator', 'operatorCard'])

  const selectedOperator = useMemo(
    () => options.operators?.find((operatorOption) => operatorOption.value === operator),
    [options.operators, operator],
  )
  const selectedCard = useMemo(() => {
    const selectedCard = selectedOperator?.operatorCards?.find((cardOption) => cardOption.value === operatorCard)
    if (selectedCard) setCardName(selectedCard?.label)
    return selectedCard
  }, [selectedOperator, operatorCard, setCardName])
  const isSelectedCardDealer = selectedCard?.cardUsage === ECardUsage.Dealer
  const isEditing = mode === EFormMode.Edit
  const isViewing = mode === EFormMode.View
  const hasDailyValue = ![EBenefitType.Transport, EBenefitType.TransportPaper].includes(benefitType)

  const updateOptions = useCallback(
    ({
      targetOptions,
      options,
      resetIgnore,
    }: {
      targetOptions: 'cards' | 'operators'
      options: any[]
      resetIgnore?: TResetOption[]
    }) => {
      setOptions((state) => ({ ...state, [targetOptions]: options }))
      if (mode === EFormMode.Add)
        if (options.length === 1)
          form.setValue(targetOptions === 'operators' ? 'operator' : 'operatorCard', options[0].value, {
            shouldValidate: true,
          })
        else resetSelections(form.setValue, resetIgnore)
    },
    [form, mode],
  )

  useEffect(() => {
    // update benefit type selection
    if (mode === EFormMode.Add)
      if (benefits.length === 1) form.setValue('benefitType', benefits[0].value, { shouldValidate: true })
      else resetSelections(form.setValue)
    // update operators options and selection
    const selectedBenefit = benefits.find((benefit) => benefit.value === benefitType)
    if (selectedBenefit)
      updateOptions({
        targetOptions: 'operators',
        options: selectedBenefit.operators,
        resetIgnore: ['benefitType'],
      })
  }, [benefitType, benefits, form, updateOptions, mode])

  // update operatorCards options and selection
  useEffect(() => {
    if (selectedOperator)
      updateOptions({
        targetOptions: 'cards',
        options: selectedOperator.operatorCards,
        resetIgnore: ['benefitType', 'operator'],
      })
  }, [operator, benefits, updateOptions, selectedOperator, mode])

  useEffect(() => {
    if (card) {
      // the way how this benefitId is obtained, should be change as soon as possible
      // it is needed to ask for changes on the Api requisition to bring this id in it
      // todo
      if (mode === EFormMode.Add) {
        const existentBenefit = existentBenefitsBinds?.find((existent) => existent.cardName === selectedCard?.label)
        if (existentBenefit) {
          form.setValue('benefitId', existentBenefit.benefitId)
          form.setValue('status', existentBenefit.status)
          setMode(EFormMode.Edit)
        }
      }
      if (Boolean(card.cardNumber)) form.setValue('cardNumber', card.cardNumber, { shouldValidate: true })
      form.setValue('operatorRegistration', card.operatorRegistration, { shouldValidate: true })
      form.setValue(
        'productsIds',
        card.dailyRates.filter((rate) => rate.enabled).map((rate) => rate.value),
        { shouldValidate: true },
      )
    }
  }, [card, form, setMode, existentBenefitsBinds, selectedCard, mode, setCardName])

  return (
    <form id='employeeBenefitBindForm' onSubmit={form.handleSubmit(onSubmit)} style={{ display: !visible && 'none' }}>
      <Grid container spacing='16px'>
        <Grid item xs={12} sm={6}>
          <Controller
            control={form.control}
            name='benefitType'
            rules={requiredFieldRule}
            render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
              <Select
                value={value}
                inputRef={ref}
                onChange={(newValue) => {
                  onChange(newValue)
                }}
                label='Benefício'
                id={`${id}_${name}`}
                variant='outlined'
                options={benefits}
                isLoadingOptions={isLoadingBenefitData}
                blocked={isEditing || isViewing}
                error={Boolean(error)}
                helperText={error?.message}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={form.control}
            name='operator'
            rules={requiredFieldRule}
            render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
              <Select
                value={value}
                onChange={(newValue) => {
                  onChange(newValue)
                }}
                isLoadingOptions={isLoadingBenefitData}
                inputRef={ref}
                label='Operadora'
                id={`${id}_${name}`}
                variant='outlined'
                options={options.operators}
                blocked={benefitType < 1 || isEditing || isViewing}
                error={Boolean(error)}
                helperText={error?.message}
                required
              />
            )}
          />
        </Grid>
        {!isViewing && (
          <>
            <Grid item xs={12} sm={6}>
              <Controller
                control={form.control}
                name='operatorCard'
                rules={requiredFieldRule}
                render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    label='Nome Cartão'
                    id={`${id}_${name}`}
                    variant='outlined'
                    options={options.cards}
                    isLoadingOptions={isLoadingBenefitData}
                    blocked={!operator || isEditing || isViewing}
                    error={Boolean(error)}
                    helperText={error?.message}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={form.control}
                name='operatorRegistration'
                rules={{ required: isSelectedCardDealer ? false : 'Campo requerido' }}
                render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    label={`Matrícula ${selectedOperator?.label ?? ''}`}
                    id={`${id}_${name}`}
                    placeholder='XXXXXXXX'
                    blocked={!operatorCard || isSelectedCardDealer || isViewing}
                    error={Boolean(error)}
                    helperText={error?.message}
                    required={!isSelectedCardDealer}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={form.control}
                name='cardNumber'
                render={({ field: { name, ref, onChange, value } }) => {
                  const props = {
                    value,
                    onChange,
                    inputRef: ref,
                    label: 'Número do Cartão',
                    id: `${id}_${name}`,
                  }
                  return isSelectedCardDealer && selectedCard?.availableCards ? (
                    <Select
                      {...props}
                      variant='outlined'
                      options={selectedCard.availableCards}
                      placeholder={selectedCard.availableCards.length > 0 ? 'Selecione' : 'Sem cartões'}
                      blocked={
                        !operatorCard || !Boolean(card?.cardNumber) || !Boolean(card?.operatorRegistration) || isViewing
                      }
                    />
                  ) : (
                    <TextField
                      {...props}
                      placeholder='00000000'
                      blocked={!operatorCard || (isSelectedCardDealer && isEditing) || isViewing}
                    />
                  )
                }}
              />
            </Grid>
          </>
        )}

        {(hasDailyValue || isViewing) && (
          <Grid item xs={12} sm={6}>
            <Controller
              control={form.control}
              name='dailyValue'
              rules={requiredFieldRule}
              render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
                <MaskedTextField
                  value={value}
                  onChange={onChange}
                  inputRef={ref}
                  label='Valor Diário'
                  mask={'monetary'}
                  placeholder='R$ 00,00'
                  id={`${id}_${name}`}
                  blocked={!operatorCard || isViewing}
                  error={Boolean(error)}
                  helperText={error?.message}
                  required
                />
              )}
            />
          </Grid>
        )}

        {(!hasDailyValue || isViewing) && (
          <Grid item xs={12} sm={6}>
            <Controller
              control={form.control}
              name='productsIds'
              rules={requiredFieldRule}
              render={({ field: { name, ref, onChange, value }, fieldState: { error } }) => (
                <Multiselect
                  ref={ref}
                  options={(card?.dailyRates as any) || []}
                  label='Tarifas Diárias'
                  variant='outlined'
                  value={value}
                  onChange={onChange}
                  placeholder='Selecione'
                  id={`${id}_${name}`}
                  blocked={!operatorCard || !card || isLoadingCardData || isViewing}
                  isLoadingOptions={isLoadingCardData}
                  error={Boolean(error)}
                  helperText={error?.message}
                  required
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}
