import { Grid, MaskedTextField, TextField, Typography } from '@stationkim/front-ui'
import { Controller } from 'react-hook-form'
import { useGetAddressByCep } from '../../../hooks/useGetAddressByCep'
import { EmployeeFormFragmentSkeleton } from './employeeFormFragmentSkeleton'
import { employeeFormRules } from '../utils/employeeFormRules'
import { EmployeeFormFragment } from '../employeeForm'

interface AddressDataProps extends EmployeeFormFragment {}

export const AddressData = ({ form, blocked, isLoading, id }: AddressDataProps) => {
  const { control } = form
  const {
    getAddress: { mutate, isLoading: isLoadingAddress },
  } = useGetAddressByCep()

  const getAddress = () => {
    const cep = form.getValues('cep')
    if (cep?.length >= 8)
      mutate(
        { cep },
        {
          onSuccess: (res) => {
            const data = res?.data
            const formValues = form.getValues()
            form.reset({
              ...formValues,
              livingState: data.uf,
              city: data.localidade,
              addressDescript: data.logradouro,
              district: data.bairro,
            })
          },
        },
      )
  }

  if (isLoading || isLoadingAddress) return <EmployeeFormFragmentSkeleton />
  return (
    <Grid container spacing='16px'>
      <Grid item xs={12}>
        <Typography variant='h2'></Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='cep'
          rules={employeeFormRules.cep}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <MaskedTextField
              id={`${id}_${name}`}
              label='CEP'
              mask={{ mask: '00000-000' }}
              placeholder='00000-000'
              blocked={blocked || isLoadingAddress}
              unmask
              onChange={(newValue) => {
                onChange(newValue)
              }}
              onKeyUp={getAddress}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='livingState'
          rules={employeeFormRules.livingState}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Estado'
              placeholder='Estado'
              blocked={true}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='city'
          rules={employeeFormRules.city}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Cidade'
              placeholder='Cidade'
              blocked={true}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='district'
          rules={employeeFormRules.district}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Bairro'
              placeholder='Bairro'
              blocked={blocked || isLoadingAddress}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='addressDescript'
          rules={employeeFormRules.addressDescript}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Endereço'
              placeholder='Endereço'
              blocked={blocked || isLoadingAddress}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='numberAddress'
          rules={employeeFormRules.numberAddress}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Número'
              placeholder='0000'
              blocked={blocked || isLoadingAddress}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name='complement'
          rules={employeeFormRules.complement}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextField
              id={`${id}_${name}`}
              label='Complemento'
              placeholder='Nº apartamento, bloco...'
              blocked={blocked || isLoadingAddress}
              onChange={onChange}
              ref={ref}
              value={value}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
