import { Box, Button, Grid, Icon, Search, Skeleton, Typography } from '@stationkim/front-ui'
import { useEffect, useState } from 'react'
import { useImportResults } from './hooks/useImportResults'
import { useTheme } from '@mui/material'
import { faArrowUpRightFromSquare, faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { CooldownButton } from './components/cooldownButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { BrokerHOC } from '../brokerHOC'
import { EmployeeRegisterDialog } from './components/employeeRegisterDialog'
import { ConfirmDialog } from './components/confirmDialog/confirmDialog'
import { FieldTableVirtualized } from './components/fieldTableVirtualized/fieldTableVirtualized'
import { AddEmployeeToOrder } from './components/addEmployeeToOrder/addEmployeeToOrder'
import { useCreateOrder } from './hooks/useCreateOrder'
import { useDeleteEmployee } from './hooks/useDeleteEmployee'
import { useEditEmployee } from './hooks/useEditEmployee'
import { useFetchImportErrors } from './hooks/useFetchImportErrors'

const ResultCounter = ({
  isLoading,
  type = 'employee',
  value,
}: {
  isLoading: boolean
  type?: 'employee' | 'error'
  value: string | number
}) => {
  const theme = useTheme()
  const typeText: { [Key in 'employee' | 'error']: string } = {
    employee: 'Colaboradores no pedido',
    error: 'Erros',
  }
  return (
    <Typography
      variant='h5'
      sx={{
        display: 'flex',
        gap: '8px',
        fontWeight: 400,
        '& span': {
          color: type === 'error' && Number(value) > 0 ? theme.palette.error.main : 'initial',
          fontSize: '1.5rem',
          fontWeight: 600,
        },
      }}
    >
      {typeText[type]}: {isLoading ? <Skeleton variant='rounded' width='32px' /> : <span>{value}</span>}
    </Typography>
  )
}

export const BrokerImportResults = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const importId = location?.state?.importId
  const [employeeToRegister, setEmployeeToRegister] = useState()
  const [isAddEmployeeDialogOpen, setIAddEmployeeDialogOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const {
    id,
    columns,
    companyId,
    employeesCount,
    errorsCount,
    fileName,
    keyField,
    layoutId,
    rows,
    totalValue,
    isLoading: isLoadingImportResult,
  } = useImportResults({
    importId,
    setEmployeeToRegister,
  })
  const { createOrder, isLoading: isLoadingCreateOrder } = useCreateOrder()
  const { deleteRow, isLoading: isLoadingDeleteRow } = useDeleteEmployee()
  const { editRow, isLoading: isLoadingEditRow } = useEditEmployee()
  const { getReport, isLoading: isLoadingErroReport } = useFetchImportErrors()

  const isLoading = isLoadingImportResult || isLoadingCreateOrder || isLoadingDeleteRow || isLoadingEditRow

  const hasError = errorsCount > 0
  const isAllErrors = errorsCount === rows.length
  const [search, setSearch] = useState('')
  const searchOnList = () => {
    if (search === '') return rows
    return rows.filter((row) => {
      return Object.keys(row).some((column) => {
        if (typeof row[column] === 'string') {
          return row[column].toLowerCase().includes(search.toLowerCase())
        }
        return false
      })
    })
  }
  useEffect(() => {
    if (!importId) navigate('/recargas')
  }, [navigate, importId])

  const filteredList = searchOnList()
  const visibleRows = isLoading || rows?.length >= 10 ? 11 : rows?.length

  const allUsersAreRegistered = rows.every((row) => {
    // validação focada no comportamento otimo
    // revisar para global (row.novoColaborador)
    if (!row?.errors) return true
    return false
  })

  const handleOrderConclusion = () => {
    if (!allUsersAreRegistered) setOpen(true)
    else {
      createOrder({ importId })
      setOpen(false)
    }
  }

  return (
    <>
      <BrokerHOC>
        {employeeToRegister && (
          <EmployeeRegisterDialog
            employeeToRegister={employeeToRegister}
            onClose={() => setEmployeeToRegister(undefined)}
          />
        )}
        {isAddEmployeeDialogOpen && (
          <AddEmployeeToOrder
            id={id}
            rows={rows}
            columns={columns}
            layoutId={layoutId}
            companyId={companyId}
            importId={importId}
            onClose={() => setIAddEmployeeDialogOpen(false)}
            identifiersInUse={rows?.map((row: any) => row[keyField])}
            keyField={keyField}
          />
        )}
        {open && (
          <ConfirmDialog
            open={open}
            onClose={() => setOpen(false)}
            onConfirm={() => {
              createOrder({ importId })
              setOpen(false)
            }}
          />
        )}
        <Grid container spacing='16px' sx={{ padding: '32px' }} alignItems='center'>
          {!isLoading && (
            <Grid item xs={12} sx={{ marginBottom: '12px' }}>
              <Box
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: hasError ? theme.palette.error.dark : theme.palette.success.dark,
                  padding: '6px 16px',
                  borderRadius: '4px',
                }}
              >
                <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                  <Icon icon={hasError ? faExclamationCircle : faCheckCircle} />
                  <Typography variant='subtitle2'>
                    {hasError ? 'A importação não foi completa' : 'Importação concluída!'}
                  </Typography>
                </Box>
                <Box sx={{ paddingLeft: '26px' }}>
                  <Typography variant='label3'>
                    {hasError
                      ? `Foram encontrados erros em alguns dados informados no arquivo importado "${fileName}".` +
                        ' Edite os itens, ou baixe o relatório de erros e reenvie o arquivo com os erros corrigidos em um novo pedido.' +
                        ' Ao continuar, o pedido será feito de forma parcial, apenas com os colaboradores que foram importados com sucesso.'
                      : 'Verifique as informações e continue para a próxima etapa.'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing='24px'>
              <Grid item xs={12} sm={'auto'} md='auto'>
                <ResultCounter isLoading={isLoading} value={employeesCount} />
              </Grid>
              <Grid item xs={12} sm={'auto'} md='auto'>
                <ResultCounter isLoading={isLoading} type='error' value={errorsCount} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={5} xl={4} sx={{ marginTop: { xs: 0, sm: '8px' } }}>
            <Search placeholder='Buscar na lista abaixo' onSearch={(value) => setSearch(value)} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md='auto'
            sx={{
              marginLeft: 'auto',
              marginTop: { xs: 0, sm: '8px' },
            }}
          >
            {hasError ? (
              <Button
                variant='outlined'
                fullWidth
                endIcon={<Icon icon={faArrowUpRightFromSquare} />}
                sx={{
                  height: {
                    xs: 'fit-content',
                    sm: '48px',
                  },
                }}
                disabled={isLoadingErroReport}
                onClick={() => getReport({ importId })}
              >
                Baixar relatório de erros
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <FieldTableVirtualized
              visibleRows={visibleRows}
              rows={filteredList}
              columns={columns}
              isLoading={isLoading}
              onRowDelete={({ row }) => {
                deleteRow(row.id)
              }}
              onRowEdit={({ row }) =>
                editRow({
                  importId,
                  payload: row,
                })
              }
              frozenActionColumn='right'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={() => setIAddEmployeeDialogOpen(true)}
              variant={'softText'}
              startIcon={<Icon icon={faPlusCircle} />}
            >
              Adicionar Colaborador
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ margin: '8px  0 8px auto ', display: 'block', width: 'fit-content', fontWeight: 600 }}>
              VALOR TOTAL: R$ {totalValue}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
            <Button
              variant='outlined'
              fullWidth
              onClick={() => navigate('/recargas/pedido_recarga_broker', { state: { isDealer: false } })}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md='auto'>
            <CooldownButton
              disabled={isAllErrors}
              skipCount={errorsCount === 0}
              isLoading={isLoading}
              onClick={handleOrderConclusion}
            />
          </Grid>
        </Grid>
      </BrokerHOC>
    </>
  )
}
