import { useMemo } from 'react'
import { AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { FieldTableVirtualizedProps, EFieldMasks, formatMoney, Icon } from '@stationkim/front-ui'
import api from '@/infra/api'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { EMascaras, ETipoCampoTabela } from '@/main/enums'
import { FieldTableVirtualizedColumns } from '../components/fieldTableVirtualized/fieldTableVirtualized'

type TApiColumn = {
  columnDisplayName: string
  columnValue: string
  editable: boolean
  align: string
  displayType: ETipoCampoTabela
  maskType: EMascaras
  dependents: string[]
  isRequired: boolean
}

type TApiRow = Record<string, string | number | null> & { erros: Record<string, any> }

interface APIReturn {
  dataCriacao: string
  id: number
  idEmpresa: string
  idImportacao: string
  idRevenda: string
  idUsuario: string
  nomeArquivo: string
  situacao: number
  valorTotal: number
  layoutConfiguracaoId: number
  tabela: {
    columns: TApiColumn[]
    rows: TApiRow[]
  }
}

type TApiResponse = AxiosResponse<APIReturn>

const defaultValue = {
  fileName: '',
  totalValue: formatMoney(0),
  employeesCount: 0,
  errorsCount: 0,
  columns: [],
  rows: [],
  keyField: undefined,
  companyId: undefined,
  layoutId: undefined,
  id: 0,
}

const maskEnumConversor = (maskEnumValue: EMascaras) => {
  return {
    [EMascaras.Cnpj]: EFieldMasks.CNPJ,
    [EMascaras.Cpf]: EFieldMasks.CPF,
    [EMascaras.Monetario]: EFieldMasks.Monetary,
    [EMascaras.Produto]: EFieldMasks.Product,
    [EMascaras.Numerico]: EFieldMasks.Numeric,
  }[maskEnumValue]
}

const getColumnsConfig = ({
  rawColumns,
  onNewEmployeeIconClick,
}: {
  rawColumns: TApiColumn[]
  onNewEmployeeIconClick: any
}): FieldTableVirtualizedProps['columns'] => {
  if (!rawColumns) return []

  const visibleColumns = rawColumns.filter((column) => column.displayType !== ETipoCampoTabela.ModalColaborador)

  const hasNewEmployeeColumn = !rawColumns.every((column) => column?.displayType === ETipoCampoTabela.Tabela)
  const columns = visibleColumns.map((column) => {
    const columnConfig: FieldTableVirtualizedColumns = {
      ...column,
      key: column.columnValue,
      dataKey: column.columnValue,
      title: column.columnDisplayName,
      width: 220,
      editable: column.editable,
      mask: maskEnumConversor(column.maskType),
      rules: { required: column.isRequired ? 'Campo obrigatório' : false },
    }
    return columnConfig
  })

  if (hasNewEmployeeColumn) {
    const newEmployeeColumn = {
      title: 'Novo Colaborador',
      width: 48,
      minWidth: 48,
      key: 'novoColaborador',
      dataKey: 'novoColaborador',
      alignment: 'center',
      editable: false,
      resizable: false,
      rules: null,
      dataGetter: ({ rowData }) => {
        return rowData.novoColaborador ? (
          <Icon
            onClick={() => {
              onNewEmployeeIconClick(rowData)
            }}
            icon={faUserPlus}
            sx={(theme: Theme) => ({
              color: theme.palette.warning.main,
              cursor: 'pointer',
            })}
          />
        ) : null
      },
    }
    columns.splice(1, 0, newEmployeeColumn)
  }
  return columns
}

const getRowsConfig = (rawRows: TApiRow[]): FieldTableVirtualizedProps['rows'] => {
  if (!rawRows) return []
  const rows: FieldTableVirtualizedProps['rows'] = rawRows.map((rawRow) => {
    const row = { ...rawRow, errors: rawRow.erros }
    if (Object.keys(row.errors).every((key) => row.errors[key] === null)) delete row.errors
    delete row.erros
    return row
  })
  return rows
}

const getErrorsCount = (rawRows: TApiRow[]): number | null => {
  if (!rawRows) return null
  return rawRows.reduce((total, row) => {
    const hasError = Object.keys(row?.erros).some((key) => Boolean(row.erros[key]))
    if (hasError) return ++total
    return total
  }, 0)
}

export interface IUseImportResultsReturn {
  id: number
  keyField: string
  companyId: string
  fileName: string
  totalValue: string
  layoutId: number
  employeesCount: number
  errorsCount: number
  isLoading: boolean
  columns: FieldTableVirtualizedProps['columns']
  rows: FieldTableVirtualizedProps['rows']
}

export const useImportResults = ({
  importId,
  setEmployeeToRegister,
}: {
  importId: string
  setEmployeeToRegister?: React.Dispatch<(prevState: undefined) => undefined>
}): IUseImportResultsReturn => {
  const { data, isFetching } = useQuery<TApiResponse>({
    queryKey: ['brokerImportResults', importId],
    queryFn: async () => {
      const response = await api.get(`rh-bff/brokerapi/importacao/${importId}`)
      if (response.data.tabela && response.data.id > 0) return response
      else throw new Error('Importação indisponível')
    },
    enabled: Boolean(importId),
    retry: 10,
    retryDelay: 5000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const results = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return defaultValue
    const keyField = rawData.tabela?.columns?.find(
      (column) => column?.columnDisplayName === 'IDENTIFICADOR OPERADORA',
    )?.columnValue
    return {
      id: rawData.id,
      keyField,
      companyId: rawData.idEmpresa,
      layoutId: rawData.layoutConfiguracaoId,
      fileName: rawData.nomeArquivo,
      employeesCount: rawData.tabela?.rows?.length,
      totalValue: formatMoney(rawData.valorTotal),
      columns: getColumnsConfig({
        rawColumns: rawData.tabela?.columns,
        onNewEmployeeIconClick: setEmployeeToRegister,
      }),
      rows: getRowsConfig(rawData?.tabela?.rows),
      errorsCount: getErrorsCount(rawData?.tabela?.rows),
    }
  }, [data, setEmployeeToRegister])

  return {
    isLoading: isFetching,
    ...results,
  }
}
